/* From Uiverse.io by Fernando-sv */
.loader {
    border: 4px solid white;
    border-left-color: transparent;
    border-radius: 50%;
}

.loader {
    border: 4px solid white;
    border-left-color: transparent;
    width: 30px;
    height: 30px;
}

.loader {
    border: 4px solid white;
    border-left-color: transparent;
    width: 30px;
    height: 30px;
    animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}