main {
    overflow: hidden !important;
}

.breadcrumb>* {
    font-size: large;
}

.breadcrumb a {
    font-size: large;
}

/* modal */
.cancel:hover {
    background-color: brown !important;
    color: aliceblue !important;
}

.bg-cv {
    background-color: #262B40;

}


/* loading */
.line {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin: 0 1px;
    border-radius: 15px;
    background-color: #F5B759;
}

.load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
}

.load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
}

.load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
}


/* login */

.form-hight {
    min-height: 13.5cm;
}

.fs-8 {
    font-size: small;
}

/* .accordion-button::after {
    margin-right: auto !important;
    margin-left: 0 !important;
} */

.button {
    cursor: pointer;
}


@keyframes loadingC {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, 0);
    }
}