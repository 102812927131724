.clock {
    padding: 10px 30px;
    position: fixed;
    bottom: 0;
    left: 50%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 20px 20px 0 0;
    height: 50px;
    transition: all 0.5s ease-out;
}

.date {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    opacity: 0;
    transition: all 0.8s ease-out;
    font-size: 15px;

}

.clock:hover .date {
    opacity: 1;
    font-size: 22px;
}

.clock:hover {
    height: 140px;
    cursor: pointer;
}

/* From Uiverse.io by mrhyddenn */
.loader {
    position: relative;
    transform: scale(2);
    border-radius: 50%;
    border: 1px solid;
    width: 30px;
    height: 30px;
    color: black;
}

.loader::after {
    position: absolute;
    width: 0px;
    height: 10px;
    display: block;
    border-left: 1px solid black;
    content: '';
    left: 14px;
    border-radius: 1px;
    top: 4px;
    animation-duration: 1s;
}

.loader::before {
    position: absolute;
    width: 0px;
    height: 10px;
    display: block;
    border-left: 1px solid black;
    content: '';
    left: 14px;
    border-radius: 1px;
    top: 4px;
    animation-duration: 40s;
}

.loader::before,
.loader::after {
    transform-origin: bottom;
    animation-name: dial;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes dial {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}